html *{
  letter-spacing: 2px;
}

.hero-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.montserrat-large-title {
  font-family: "Montserrat", sans-serif;
  font-weight: "bold";
  font-size: 12vw;
}

.montserrat-large-title-absolute {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  font-weight: "bold";
  font-size: 12vw;
}

#home:hover {
  color: #f9844a;
}

#support:hover {
  color: #f9844a;
}

#login:hover {
  color: #f9844a;
}

#loginButton:hover {
  background-color: white;
}
